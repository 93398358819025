<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-control">
      <v-img
        class="v-step-5"
        src="../../assets/img/life-control-banner.png"
        gradient="0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)"
        style="border-radius: 20px"
      >
        <div
          style="width: 35%; height: 100%; margin: auto"
          class="d-flex flex-column justify-center"
        >
          <p style="padding-top: 9%" class="text-title-ligth mb-6">
            Control de vida 666
          </p>
          <p class="controlLife__banner-text">
            Tome el control de las variables que hacen de su Proyecto de Vida
            una realidad a su alcance.
          </p>
          <div>
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="irControl()"
                style="color: #fff"
                rounded
                class="text-1-lines"
                :style="{ 'background-color': hover ? '#324CA1' : '#466BE3' }"
              >
                ver más
                <img
                  width="20"
                  src="../../assets/icon/arrow-right-button.svg"
                />
              </v-btn>
            </v-hover>
          </div>
        </div>
      </v-img>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-control">
      <v-img
        @click="irControl()"
        class="v-step-5 img-control-sm"
        src="../../assets/img/life-control-banner.png"
        gradient="0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)"
        style="border-radius: 20px"
      >
        <p class="text-control-sm">Control de vida</p>
      </v-img>
    </div>
  </div>
</template>
<script>
import alert from "@/mixins/alert";

export default {
  mixins: [alert],
  data() {
    return {};
  },
  methods: {
    async irControl() {
      this.$router.push({ name: "lifeControl" });
    },
    irApp() {
      window.open("https://app.brm.co/auth/login", "_black");
    },
  },
};
</script>
<style>
.mb-sm-control {
  display: none;
}

.controlLife__banner-text {
  font-family: "Roboto";
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-control {
    display: none;
  }
  .mb-sm-control {
    display: block;
    text-align: initial;
  }
  .text-control-sm {
    text-align: center;
    padding-top: 11%;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    font-family: "AsapRegular";
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-control {
    display: none;
  }
  .mb-sm-control {
    display: block;
    text-align: initial;
  }
  .text-control-sm {
    text-align: center;
    padding-top: 11%;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    font-family: "AsapRegular";
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-control {
    display: none;
  }
  .mb-sm-control {
    display: block;
    text-align: initial;
  }
  .text-control-sm {
    text-align: center;
    padding-top: 11%;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    font-family: "AsapRegular";
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-control {
    display: none;
  }
  .mb-sm-control {
    display: block;
    text-align: initial;
  }
  .text-control-sm {
    text-align: center;
    padding-top: 11%;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #ffffff;
    font-family: "AsapRegular";
  }
}
</style>
