<template>
  <div class="mx-3">
    <!-- DESKTOP -->
    <div class="mb-xl-home" v-if="!isMobile">
      <v-row class="pl-8 pr-8" justify="center">
        <v-col class="mt-1 mr-0 pr-0 pl-0" cols="9">
          <NiewMain class="v-step-1"></NiewMain>
        </v-col>
        <v-col cols="3" class="ml-0 pr-0">
          <Flash class="mt-1 v-step-2 active-tour"></Flash>
        </v-col>

        <v-col v-if="listUsersReconocimientos.length > 0" cols="12">
          <div class="d-flex align-center mb-3">
            <p class="custom-title-reconocimientos mb-0">{{ $translate("home.recognitions") }} </p>
            <v-hover v-slot="{ hover }">
              <v-btn @click="navegarARuta" rounded small style="color: #fff" :style="{
                'position': 'relative',
                'left': '20px',
                'background-color': hover ? '#324CA1' : '#466BE3',
              }">
                {{ $translate("home.buttonSeeMore") }} 
                <img width="20" src="../../assets/icon/arrow-right-button.svg" />
              </v-btn>
            </v-hover>
          </div>

          <div class="mb-xl-action">
            <v-slide-group multiple show-arrows prev-icon="mdi-chevron-left-circle-outline"
              next-icon="mdi-chevron-right-circle-outline">
              <v-slide-item v-for="user in listUsersReconocimientos" :key="user.id">
                <v-card class="mx-2 hover-action mt-3 mb-2" :input-value="active" active-class="purple white--text"
                  style="border-radius: 8px" depressed rounded width="450">
                  <v-row align="center">
                    <v-col class="mt-2 pl-6 pr-3" cols="3">
                      <v-img width="60" height="60"
                        style="border-radius: 50px; border: 2px solid; border-color: #466be3;" :src="user.img">
                      </v-img>
                    </v-col>
                    <v-col class="pt-6 pb-4 pl-0 pr-5 text-left" cols="9">
                      <p class="text-blue text-1-1-lines text-left mb-0 pb-0"
                        style="color: #353535;text-transform: capitalize;">
                        {{ user.name }} <!-- Mostramos el nombre del usuario -->
                      </p>
                      <div class="bottom-container-card-reconocimientos">
                        <p class="text-1-lines mb-0 pb-0 " style="color: #626262; width: 65%;">
                          {{ user.position }} <!-- Mostramos el cargo del usuario -->
                        </p>
                        <p class="text-1-lines mb-0 pb-0 " style="color: #466be3; width: 35%;">
                          ({{ user.program }}) <!-- Mostramos el programa del usuario -->
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-col>


        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-col class="mb-0 pb-2 pl-0 ml-0" cols="12">
              <p class="text-title-xl">{{ $translate("home.OtherNews") }}</p>
            </v-col>
            <v-col class="pl-0 pr-0" cols="12">
              <Niews class="v-step-3"></Niews>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-col class="mb-0 pb-3" cols="12">
              <p class="text-title-xl">{{ $translate("home.toDoToday") }}</p>
            </v-col>
            <v-col class="pl-0 pr-0" cols="12">
              <action-today class="v-step-4"></action-today>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-0 pl-0 pr-3" cols="12">
          <v-row align="center" justify="center">
            <v-col class="mb-10 pb-0 p-0 pr-0" cols="12" v-if="flagControlLife == 1">
              <index-life></index-life>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- MOBILE -->
    <div class="mb-sm-home-views-1" v-else>
      <v-row class="mt-16 pt-3">
        <navbar-user class="hidden"></navbar-user>
        <v-col cols="12" class="ml-0">
          <Flash class="mt-8 v-step-2 active-tour v-step-mobile-1"></Flash>
        </v-col>
        <v-col class="mt-0 mr-0 pr-0" cols="12">
          <NiewMain class="v-step-1 v-step-mobile-2"></NiewMain>
        </v-col>
        <v-col class="mb-0 pb-0" cols="12">
          <p class="text-title-xl pb-0 mb-2 v-step-mobile-3">
            {{ $translate("home.toDoToday") }}
          </p>
        </v-col>
        <v-col class="pl-0 pr-0" cols="12">
          <action-today class="v-step-4"></action-today>
        </v-col>
        <v-col class="ml-0 pl-1 pr-2" cols="12">
          <index-life v-if="flagControlLife == 1" class="v-step-mobile-4"></index-life>
        </v-col>
        <v-col class="ml-0 pl-4 pr-4 mb-15" cols="12">
          <!-- APLICACIONES FRECUENTES -->
          <p class="text-title-xl pb-4 mb-2 v-step-mobile-5">
            Aplicativos frecuentes
          </p>
          <v-layout justify-center>
            <v-progress-circular v-if="overlay" :size="40" indeterminate color="#466BE3"></v-progress-circular>
          </v-layout>

          <v-slide-group multiple show-arrows prev-icon="mdi-chevron-left-circle-outline"
            next-icon="mdi-chevron-right-circle-outline">
            <v-slide-item v-for="data in listApps" :key="data.id" v-slot="{ active, toggle }">
              <div class="mx-2 mt-0 mb-5 app-frencuently-sm text-center" :input-value="active"
                active-class="purple white--text" depressed rounded @click="open(data)">
                <div style="text-align: -webkit-center; height: 60px" class="d-flex justify-center align-center">
                  <v-img max-width="60" :src="data.logo"> </v-img>
                </div>
                <p class="txt-app-fre-sm text-center text-1-lines mb-0 index-home__apps-frequent">
                  {{ data.nombre_app }}
                </p>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </div>

    <!-- Dialog para formularios pendientes -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-text class="text-left pt-5 text-pending-forms">
          <p>
            Hola
            <b class="text-capitalize"> {{ name }} </b>,
          </p>
          <p>
            Queremos recordarle que tiene formularios pendientes por
            diligenciar, puede ingresar dando click en el botón o ingresar a la
            respectiva sección para su diligenciamiento.
          </p>
          <p>Muchas gracias colaborador.</p>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-btn text dark rounded class="btn-main" @click="navigateToForms()">
            Ir a formularios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert :open="alert.open" :text="alert.txt" :title="alert.title" :type="alert.type" :redirect="alert.redirect"
      @close="alert.open = false">
    </Alert>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue"; // Importa un componente de alerta
import Niews from "./NewsLine.vue"; // Importa un componente para mostrar líneas de noticias
import NiewMain from "./NewsMain.vue"; // Importa el componente principal de noticias
import Flash from "./Flash.vue"; // Importa un componente para mensajes temporales
import IndexLife from "./ControlLife.vue"; // Importa un componente para controlar la vida
import ActionToday from "./ActionToday.vue"; // Importa un componente para acciones del día
import moment from "moment"; // Importa la biblioteca moment.js para manejar fechas
import { Hooper, Slide, Pagination as HooperPagination } from "hooper"; // Importa componentes de Hooper para carruseles
import "hooper/dist/hooper.css"; // Importa los estilos de Hooper
import { ref, defineAsyncComponent } from "vue"; // Importa funciones de Vue para definir componentes

export default {
  data() {
    return {
      isMobile: false, // Variable para saber si se está en móvil
      itemsToShow: 2.5, // Número de items a mostrar en el carrusel
      listApps: [], // Lista de aplicaciones
      overlay: false, // Variable para manejar la superposición
      step: 1, // Paso actual para el tour
      flagControlLife: 0, // Bandera para controlar la vida
      info: {}, // Información del usuario
      id: null, // ID del usuario
      infoToken: {
        document: "", // Documento del token
      },
      token: null, // Token de autenticación
      token64: null, // Token en formato Base64
      id_profile: null, // ID del perfil del usuario
      dialog: false, // Controla si el diálogo está abierto
      name: "", // Nombre del usuario
      alert: {
        txt: "", // Texto de la alerta
        title: "", // Título de la alerta
        type: "", // Tipo de alerta (info, error, etc.)
        open: false, // Estado de la alerta (abierta o cerrada)
        redirect: "", // Redirección después de la alerta
      },
      listUsersReconocimientos: [],  // Aquí se almacenarán los datos del slider
    };
  },

  components: {
    // Define los componentes que se usarán en este componente
    Niews,
    NiewMain,
    Flash,
    IndexLife,
    ActionToday,
    Hooper,
    Slide,
    HooperPagination,
    Alert,
  },

  computed: {
    // Propiedades computadas que actualizan automáticamente
    getAppActivity() {
      let appActivity = this.$store.getters.getAppActivity; // Obtiene la actividad de la aplicación del store
      if (appActivity.length !== 0) {
        this.listApps = appActivity; // Asigna la actividad a listApps
        this.overlay = false; // Desactiva la superposición
      } else {
        this.overlay = true; // Activa la superposición si no hay actividades
      }
    },
    getUser() {
      let user = this.$store.getters.getUser; // Obtiene el usuario del store
      if (user) {
        this.info = user; // Asigna la información del usuario
        this.id = user.profile.id; // Asigna el ID del perfil del usuario
        this.validateControlLife(); // Valida el control de vida del usuario
      }
    },
    users() {
      return this.getUsersArray(); // Retorna el array de usuarios
    },
  },

  methods: {
    changeLanguage(lang) {
      this.$root.$data.currentLanguage = lang;
    },

    checkIfMobile() {
      // Verifica si la pantalla es móvil
      this.isMobile = window.innerWidth <= 1023; // Cambia isMobile según el ancho de la ventana
    },
    irDetailsApp(item) {
      // Navega a los detalles de una aplicación
      localStorage.setItem("app", JSON.stringify(item)); // Guarda la información de la app en localStorage
      this.$router.push({
        name: "appDetails", // Redirige a la ruta de detalles de la app
        params: { id: item._ID }, // Pasa el ID de la app como parámetro
      });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    validateControlLife() {
      // Valida el control de vida basado en el contrato
      if (this.info.contractor == "brm") {
        const today = moment().format("YYYY-MM-DD"); // Fecha de hoy
        const allow_date = moment(this.info.date_admission)
          .add(90, "days")
          .format("YYYY-MM-DD"); // Fecha permitida (90 días después de la admisión)
        if (moment(today).isAfter(allow_date)) {
          this.flagControlLife = 1; // Si hoy es después de la fecha permitida, activa la bandera
        }
      }
      sessionStorage.setItem(
        "flagControlLife",
        this.flagControlLife.toString() // Guarda la bandera en sessionStorage
      );
    },

    navigateToForms() {
      // Navega a la ruta de formularios
      this.$router.push({ name: "forms" });
    },
    navegarARuta() {
      this.$router.push({ name: 'ReconocimineosParaTi' });
    },

    open(app) {
      // Abre una aplicación en función de su URL
      if (app.url && app.url !== "#") {
        if (app.dispositivos_moviles == "false") {
          // Si la app no está disponible para móviles, muestra un alerta
          this.alert.open = true;
          this.alert.type = "alert";
          this.alert.title = "Upss...";
          this.alert.txt =
            "Lamentablemente, esta aplicación solo no se encuentra disponible para dispositivos móviles";
        } else {
          // Si está disponible, abre la URL en una nueva pestaña
          let newURL = document.createElement("a");
          newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
          newURL.href = app.url;
          newURL.target = "_blank";
          document.body.appendChild(newURL); // Agrega el enlace al DOM
          newURL.click(); // Simula un clic para abrir la URL
        }
      } else {
        // Si no hay URL, muestra un mensaje informativo
        this.alert.open = true;
        this.alert.type = "info";
        this.alert.title = "Para que sepas...";
        this.alert.txt = "Próximamente esta aplicación estará disponible";
      }
    },
    // Métodos del componente
    fillUsersReconocimientos() {
  this.loadingAll = true;  // Activamos el loading

  const requestBody = JSON.stringify({
    limit: 10,  // Puedes cambiar esto a un valor mayor si necesitas limitar la cantidad de resultados
    vigencia: 0,  // Puedes ajustarlo según las necesidades
  });

  Api.Reconocimientos()
    .getGanadoresSlider(this.token, requestBody)  // Pasamos el token y el body JSON
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Error: ${res.statusText}`);
      }
      return res.json();  // Parseamos la respuesta como JSON
    })
    .then((response) => {
      // Log para ver toda la respuesta
      console.log(response);

      if (response && response.data) {
        // Creamos un array plano para almacenar los usuarios de los reconocimientos
        const users = [];

        // Recorrer todos los datos y agregar la información de cada usuario
        response.data.forEach(item => {
          if (item.user && item.user.id) {
            users.push({
              id: item.id,  // Usamos el id principal del objeto para la clave
              name: item.user.full_name,
              position: item.cargo,
              img: item.user.img,
              program: item.programa.nombre,
            });
          } else {
            console.error("Elemento sin 'id' encontrado:", item);
          }
        });

        // Asignamos el array de usuarios al listado que usará el slider
        this.listUsersReconocimientos = users;
      } else {
        console.error("Respuesta no válida:", response);
      }

      this.loadingAll = false;
    })
    .catch((error) => {
      if (error.response) {
        console.error("Error 500: Error del servidor", error.response.status);
        console.error("Detalles del error:", error.response.data);  // Aquí puedes ver más detalles de la respuesta
      } else {
        console.error("Error desconocido:", error);
      }
      this.loadingAll = false;
    });
}
  },

  created() {
    // Método que se ejecuta cuando se crea el componente
    if (screen.width >= 708) {
      this.itemsToShow = 5.7; // Ajusta items a mostrar en base al tamaño de la pantalla
    }
    this.token = localStorage.getItem("token"); // Obtiene el token
    this.token64 = localStorage.getItem("tokenB64"); // Obtiene el token en Base64
    this.getToken(); // Llama a la función para obtener el token
    this.checkIfMobile(); // Verifica si es móvil
    window.addEventListener('resize', this.checkIfMobile); // Agrega un listener para cambios de tamaño
    this.fillUsersReconocimientos(); // Llamamos a la función para llenar el array
  },

  mounted() {
    // Método que se ejecuta cuando el componente se monta
    if (this.$tours["myTour"]) {
      this.step = this.$tours["myTour"].currentStep; // Establece el paso actual del tour
    }
    this.$store.dispatch("updateIsHome"); // Actualiza el estado en el store
    this.fillUsersReconocimientos();
  },

  beforeDestroy() {
    // Método que se ejecuta antes de destruir el componente
    window.removeEventListener('resize', this.checkIfMobile); // Remueve el listener de tamaño de ventana
  },
};
</script>


<style>
@import "./../../assets/css/main.less";

.gradient-text {
  background: linear-gradient(to right, #006400, #008000, #00FF00, #FFFF00, #FF0000, #800000, #400000);
  -webkit-background-clip: text;
  /* Para navegadores basados en WebKit (Safari, Chrome) */
  color: transparent;
  /* Hace el texto transparente */
}

/* Opcional: Añade sombra al texto para mejorar la legibilidad */
/* .gradient-text-with-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
} */
.mdi-chevron-right-circle::before {
  content: "\F0B2A";
  color: #466be3;
}

.mdi-chevron-left-circle::before {
  content: "\F0B28";
  color: #466be3;
}

/* .active-tour {
  border: 1px red solid;
  border-radius: 0.8rem;
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.2);
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
} */

.text-pending-forms {
  color: #898989;
  font-family: "ProximaNovaLight";
  font-weight: 400;
  font-size: 16px;
}

.mb-sm-home-views-1 {
  display: none;
}

.v-slide-group__next,
.v-slide-group__prev {
  justify-content: center !important;
  min-width: 28px !important;
  flex: 0 1 24px !important;
}

.index-home__apps-frequent {
  max-width: 100%;
  overflow: hidden !important;
}

/* REVISAR ESTO */

/* .text-right { 
  display: flex;
  justify-content: flex-end;
} */

/* LAPTOP 4k */

@media (max-width: 1900px) {}

/* LAPTOP LARGE */

@media (max-width: 1600px) {}

/* LAPTOP LARGE */

@media (max-width: 1400px) {}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-home {
    display: none;
  }

  .mb-sm-home-views-1 {
    display: block;
    text-align: initial;
  }

  .app-frencuently-sm {
    width: 108px;
    height: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
      0px 2px 4px rgba(70, 107, 227, 0.4);
    border-radius: 10px;
  }

  .txt-app-fre-sm {
    font-family: "proximaNovaRegular";
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #141b33;
  }

  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 35px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-home {
    display: none;
  }

  .mb-sm-home-views-1 {
    display: block;
    text-align: initial;
  }

  .app-frencuently-sm {
    width: 108px;
    height: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
      0px 2px 4px rgba(70, 107, 227, 0.4);
    border-radius: 10px;
  }

  .txt-app-fre-sm {
    font-family: "proximaNovaRegular";
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #141b33;
  }

  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 35px !important;
    flex: 0 1 24px !important;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-home {
    display: none;
  }

  .mb-sm-home-views-1 {
    display: block;
    text-align: initial;
  }

  .app-frencuently-sm {
    width: 108px;
    height: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
      0px 2px 4px rgba(70, 107, 227, 0.4);
    border-radius: 10px;
  }

  .txt-app-fre-sm {
    font-family: "proximaNovaRegular";
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #141b33;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-home {
    display: none;
  }

  .mb-sm-home-views-1 {
    display: block;
    text-align: initial;
  }

  .app-frencuently-sm {
    width: 108px;
    height: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(62, 123, 250, 0.2),
      0px 2px 4px rgba(70, 107, 227, 0.4);
    border-radius: 10px;
  }

  .txt-app-fre-sm {
    font-family: "proximaNovaRegular";
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #141b33;
  }

  .v-slide-group__next,
  .v-slide-group__prev {
    justify-content: center !important;
    min-width: 35px !important;
    flex: 0 1 24px !important;
  }
}

/*Estilos Santiago*/
.custom-title-reconocimientos {
  text-align: left;
  font-weight: 300;
  font-size: 23px;
  line-height: 37px;
  color: #353535;
  font-family: "RobotoRegular";
}

.bottom-container-card-reconocimientos {
  display: flex;
  justify-content: space-between;
}
</style>
